<template>
 <div>
    <div><h3>Результаты рассчетов</h3></div>
    <div>{{signal.earthquakeDate}}</div>
    <div>Землетрясение: {{textLatitude}}, {{textLongitude}}, магнитуда {{magnitude}} </div>
   <!--{{signal}}-->
    <!--<div style="background-color: lightgray"><a href="#">Получить результаты</a><a href="#" style="padding-left: 10px">Справка</a></div>-->
   <div style="overflow-y: auto; height: 70vh">
    <table class="result-table;" >
      <thead>
        <tr>
          <th>Защищаемый пункт</th>
          <th>Время прихода волны</th>
          <th>
            <div>Виртуальный источник:</div>
            <div style="padding-left: 15px; color: blue">Минимальная волка (м)</div>
            <div style="padding-left: 30px">Прилив</div>
            <div style="padding-left: 45px">Уровень моря</div>
          </th>
          <th>
            <div>Виртуальный источник:</div>
            <div style="padding-left: 15px; color: red">Максимальная волка (м)</div>
            <div style="padding-left: 30px">Прилив</div>
            <div style="padding-left: 45px">Уровень моря</div>
          </th>
          <th>
            <div>Виртуальный источник:</div>
            <div style="padding-left: 15px; color: blue">Время прихода минимальной волны</div>
            <div style="padding-left: 30px; color: red">Время прихода максимальной волны</div>
            <div style="padding-left: 45px"> </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="5" @click="showValues = !showValues" style="border: 1px solid black; background-color: lightgrey; cursor: pointer">Раскрыть/Скрыть</td>
        </tr>
        <tr>
          <td colspan="5"></td>
        </tr>
      </tbody>
      <result v-show="showValues" v-for="(report, index) in getCalcResults" :key="report.type + index" :report="report" :earthquake-date="signal.earthquakeDate"></result>
    </table>
   </div>
 </div>
</template>

<script>
  import Result from "./Result"
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: 'CalcResults',
    components: {
      Result
    },
    props: {
      signal: Object,
    },
    computed: {
      ...mapGetters(['getCalcResults']),
      textDate() {
        // earthquakeDate
        return ``
      },
      textLatitude() {
        return `${this.latitude} с.ш.`
      },
      textLongitude() {
        return `${this.longitude} в.д.`
      }
    },
    data() {
      return {
        latitude: this.signal.latitude,
        longitude: this.signal.longitude,
        magnitude: this.signal.magnitude,
        signalId: this.signal.signalId,

        showValues: true,
        results: []
      }
    },
    methods: {
      ...mapActions(['loadCalcResults'])
    },
    async mounted() {
      console.debug('calc results', this.signal)
      this.loadCalcResults(this.signal.id)
      // let resp = await getResults(this.signal.id);
      // this.results = resp.payload
      // logger('resp results', JSON.stringify(resp))
    }
  }
</script>

<style scoped>
  table tbody tr th, table tbody tr td, table th, table td {
    padding: 5px;
  }

  table, table th {
    border: 1px solid black;
  }

  table.result-table td {
    padding: 5px;
  }


</style>